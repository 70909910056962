import React from "react"
import Skill from "../Skill"
import { FormattedMessage, useIntl } from "react-intl"

const SkillList = ({ skills }) => {
  const { formatMessage } = useIntl()
  const skillsSorted = skills.sort(function (a, b) {
    return b.proficiency - a.proficiency
  })

  // Group by skill_list
  const grouped = skillsSorted.reduce((acc, skill) => {
    const key = skill.skill_type
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(skill)
    return acc
  }, {})

  return (
    <>
      <div className="skills-card flex-col">
        <h3 className="skill">
          <FormattedMessage id="common.hardSkillsAssessment" />
        </h3>
        {grouped?.hard?.map((skill, index) => (
          <Skill
            key={index}
            score={skill.proficiency}
            skill={skill.skill}
            context={skill.justification}
            tag={formatMessage({ id: "common.skill" })}
          />
        ))}
      </div>
      <div className="skills-card flex-col">
        <h3 className="skill">
          <FormattedMessage id="common.softSkillsAssessment" />
        </h3>
        {grouped?.soft?.map((skill, index) => (
          <Skill
            key={index}
            score={skill.proficiency}
            skill={skill.skill}
            context={skill.justification}
            tag={formatMessage({ id: "common.skill" })}
          />
        ))}
      </div>
    </>
  )
}

export default SkillList
