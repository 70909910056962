export const parseCV = (cv) => ({
  key: cv.Key.split("/").pop().split(".").slice(0, -1).join("."),
  name: cv.Profile?.name,
  source: cv.Profile?.source,
  fileName:
    cv.Profile?.fileName ||
    cv.Key.split("/").pop().split("_").slice(1).join("_"),
  isProcessed: cv.IsProcessed_Attribute,
  date: cv.LastModified,
});
