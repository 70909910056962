import React from "react"
import { useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"
import classNames from "classnames"

import { CustomLink, Spinner } from "../../common"
import { Candidate } from "./components"
import { GA_EVENTS } from "../../../constants/analytics"
import { FormattedMessage } from "react-intl"

const MatchesPage = () => {
  const jobs = useSelector((state) => state.jobs)
  const {
    candidates,
    jobStatus,
    qa: jobQa,
    noMatches,
    noRequirementsSet,
  } = jobs

  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedCandidate = searchParams.get("candidate")
  const tab = searchParams.get("tab") || "job-match"

  const ad_id = params.jobId

  if (!ad_id) {
    return (
      <div className={"matches-page"}>
        <p>
          <FormattedMessage id="common.pleaseSelectAJob" />
        </p>
      </div>
    )
  }

  const selectedCandidateInfo = candidates?.find(
    (candidate) => candidate.key === selectedCandidate,
  )

  const switchTab = (tab) => {
    setSearchParams({
      tab: tab,
      candidate: selectedCandidate,
    })
  }

  if (noRequirementsSet) {
    return (
      <div className="matches-page">
        <div className="matches-page__card">
          <p className="h4">
            <FormattedMessage id="common.stillWorkingOnJobMatch" />
          </p>
          <p className="matches-page__card-text p">
            <FormattedMessage id="message.finishCheckingRequirements" />
          </p>
          <CustomLink
            className="btn primary btn--small"
            to={`/job-match/${ad_id}/requirements`}
            trackingEvent={GA_EVENTS.JOB_MATCHES.BACK_AND_CONTINUE}
          >
            <span>
              <FormattedMessage id="message.goBack" />{" "}
              <span>
                {" "}
                <FormattedMessage id="message.andContinue" />
              </span>
            </span>
          </CustomLink>
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames("matches-page", {
        "matches-page__loading": !candidates || noMatches,
      })}
    >
      {noMatches && (
        <div className="matches-page__info-container">
          <h2>
            <FormattedMessage id="common.noMatchFound" />
          </h2>
          <p>
            <FormattedMessage id="common.tryChangingRequirementsJobDescription" />
          </p>
        </div>
      )}
      {jobStatus === "ProcessingCV" && (
        <div className="matches-page__info-container">
          <h2>
            <FormattedMessage id="common.matchingCandidatesToRequirements" />
          </h2>
          <Spinner variant="secondary" />
        </div>
      )}
      {!candidates && (
        <div>
          <Spinner />
        </div>
      )}

      {selectedCandidateInfo && (
        <Candidate
          key={selectedCandidate}
          tab={tab}
          jobQa={jobQa}
          switchTab={switchTab}
          selectedCandidateInfo={selectedCandidateInfo}
        />
      )}
    </div>
  )
}

export default MatchesPage
