import React, { useEffect, useRef, useState } from "react"
import RequirementItem from "./RequirementItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FormattedMessage, useIntl } from "react-intl"

const sections = ["skills", "experience", "education"]

const sectionTitles = {
  skills: "Skills",
  experience: "Experience",
  education: "Education",
}

const Requirements = ({ data, setData }) => {
  const [editable, setEditable] = useState("")

  useEffect(() => {
    const el = document.getElementById(editable)

    el?.focus()
  }, [editable])

  return (
    <>
      {sections.map((section, index) => {
        return (
          <Section
            {...{ data, section, editable, setEditable, setData }}
            key={`Section - ${index}`}
          />
        )
      })}
    </>
  )
}

const Section = ({ data, section, editable, setEditable, setData }) => {
  const [input, setInput] = useState("")

  const editInputRef = useRef()
  const { formatMessage } = useIntl()

  const add = () => {
    setInput("")
    setData((data) => {
      const newData = { ...data }
      newData[section].push({
        text: input,
        edited: true,
      })
      return newData
    })
  }

  return (
    <div className="box">
      <h4 className="h4">
        <FormattedMessage id={sectionTitles[section]} />
      </h4>
      <div className="box__requirements">
        {data[section]?.map((item, i) => {
          const id = `${section}-${i}`

          return (
            <RequirementItem
              key={id}
              section={section}
              index={i}
              item={item}
              editable={editable}
              setEditable={setEditable}
              setData={setData}
            />
          )
        })}

        <div className="req-add">
          <input
            ref={editInputRef}
            placeholder={formatMessage({ id: "common.add" })}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && add()}
          />

          {input.length > 2 ? (
            <button className="btn btn--icon req-add__icon" onMouseDown={add}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          ) : (
            <button
              className="btn btn--icon req-add__icon"
              onClick={() => editInputRef.current.focus()}
            >
              {addSvg}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const addSvg = (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.52829 1.882C8.66179 1.74851 8.82027 1.64261 8.99468 1.57037C9.1691 1.49812 9.35604 1.46094 9.54483 1.46094C9.73362 1.46094 9.92055 1.49812 10.095 1.57037C10.2694 1.64261 10.4279 1.74851 10.5614 1.882C10.6949 2.01549 10.8007 2.17397 10.873 2.34839C10.9452 2.52281 10.9824 2.70974 10.9824 2.89853C10.9824 3.08732 10.9452 3.27426 10.873 3.44868C10.8007 3.62309 10.6949 3.78157 10.5614 3.91507L3.69976 10.7767L0.904297 11.5391L1.6667 8.7436L8.52829 1.882Z"
      stroke="#5EC58F"
      strokeWidth="1.01653"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Requirements
