// util to format numbers into space separated thousands
export const formatPrice = (num = 0) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


export const formatText = (text = "") => {
  // Split the text by the bold text
  return text.split(/(\*\*\*.*?\*\*\*)/g).map((part, index) => {
    if (part.startsWith("***") && part.endsWith("***")) {
      return <b key={index}>{part.slice(3, -3)}</b>;
    }
    return part;
  });
}