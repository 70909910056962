export const HIRES = {
  ONE_TIME_ONLY: {
    MIN: 1,
    MAX: 10,
  },
  SUBSCRIPTION: {
    MIN: 11,
    MAX: 30,
  }
};

export const PLAN_TYPES = {
  "self-service": {
    minHires: 1,
    maxHires: 30,
    max: 31, // slider max value
  },
  "enterprise": {
    minHires: 30,
    maxHires: 50,
    max: 51, // slider max value
  }
};

export const PRICE_TIERS = {
  "self-service": {
    // ------------------- ONE-OFF -------------------
    1: {
      cvAnalysis: 100,
      jobMatches: 2,
      price: 610,
      type: "one-off"
    },
    2: {
      cvAnalysis: 200,
      jobMatches: 3,
      price: 1220,
      type: "one-off"
    },
    3: {
      cvAnalysis: 300,
      jobMatches: 5,
      price: 1830,
      type: "one-off"
    },
    4: {
      cvAnalysis: 400,
      jobMatches: 6,
      price: 2440,
      type: "one-off"
    },
    5: {
      cvAnalysis: 500,
      jobMatches: 8,
      price: 3050,
      type: "one-off"
    },
    6: {
      cvAnalysis: 600,
      jobMatches: 9,
      price: 3587,
      type: "one-off"
    },
    7: {
      cvAnalysis: 700,
      jobMatches: 11,
      price: 4124,
      type: "one-off"
    },
    8: {
      cvAnalysis: 800,
      jobMatches: 12,
      price: 4660,
      type: "one-off"
    },
    9: {
      cvAnalysis: 900,
      jobMatches: 14,
      price: 5197,
      type: "one-off"
    },
    10: {
      cvAnalysis: 1000,
      jobMatches: 15,
      price: 5734,
      type: "one-off"
    },

    // ------------------- MONTHLY SUBSCRIPTION -------------------
    11: {
      cvAnalysis: 1100,
      jobMatches: 17,
      price: 478,
      type: "subscription"
    },
    12: {
      cvAnalysis: 1200,
      jobMatches: 18,
      price: 573,
      type: "subscription"
    },
    13: {
      cvAnalysis: 1300,
      jobMatches: 20,
      price: 621,
      type: "subscription"
    },
    14: {
      cvAnalysis: 1400,
      jobMatches: 21,
      price: 669,
      type: "subscription"
    },
    15: {
      cvAnalysis: 1500,
      jobMatches: 23,
      price: 717,
      type: "subscription"
    },
    16: {
      cvAnalysis: 1600,
      jobMatches: 24,
      price: 765,
      type: "subscription"
    },
    17: {
      cvAnalysis: 1700,
      jobMatches: 26,
      price: 812,
      type: "subscription"
    },
    18: {
      cvAnalysis: 1800,
      jobMatches: 27,
      price: 860,
      type: "subscription"
    },
    19: {
      cvAnalysis: 1900,
      jobMatches: 29,
      price: 908,
      type: "subscription"
    },
    20: {
      cvAnalysis: 2000,
      jobMatches: 30,
      price: 956,
      type: "subscription"
    },
    21: {
      cvAnalysis: 2100,
      jobMatches: 32,
      price: 999,
      type: "subscription"
    },
    22: {
      cvAnalysis: 2200,
      jobMatches: 33,
      price: 1042,
      type: "subscription"
    },
    23: {
      cvAnalysis: 2300,
      jobMatches: 35,
      price: 1085,
      type: "subscription"
    },
    24: {
      cvAnalysis: 2400,
      jobMatches: 36,
      price: 1129,
      type: "subscription"
    },
    25: {
      cvAnalysis: 2500,
      jobMatches: 38,
      price: 1172,
      type: "subscription"
    },
    26: {
      cvAnalysis: 2600,
      jobMatches: 39,
      price: 1215,
      type: "subscription"
    },
    27: {
      cvAnalysis: 2700,
      jobMatches: 41,
      price: 1258,
      type: "subscription"
    },
    28: {
      cvAnalysis: 2800,
      jobMatches: 42,
      price: 1301,
      type: "subscription"
    },
    29: {
      cvAnalysis: 2900,
      jobMatches: 44,
      price: 1345,
      type: "subscription"
    },
    30: {
      cvAnalysis: 3000,
      jobMatches: 45,
      price: 1388,
      type: "subscription"
    },
  },


  "enterprise": {
    30: {
      cvAnalysis: 4500,
      jobMatches: 45,
      price: 1830,
      type: "subscription"
    },
    31: {
      cvAnalysis: 4650,
      jobMatches: 47,
      price: 1891,
      type: "subscription"
    },
    32: {
      cvAnalysis: 4800,
      jobMatches: 48,
      price: 1952,
      type: "subscription"
    },
    33: {
      cvAnalysis: 4950,
      jobMatches: 50,
      price: 2013,
      type: "subscription"
    },
    34: {
      cvAnalysis: 5100,
      jobMatches: 51,
      price: 2074,
      type: "subscription"
    },
    35: {
      cvAnalysis: 5250,
      jobMatches: 53,
      price: 2135,
      type: "subscription"
    },

    36: {
      cvAnalysis: 5400,
      jobMatches: 54,
      price: 2196,
      type: "subscription"
    },
    37: {
      cvAnalysis: 5550,
      jobMatches: 56,
      price: 2257,
      type: "subscription"
    },
    38: {
      cvAnalysis: 5700,
      jobMatches: 57,
      price: 2318,
      type: "subscription"
    },
    39: {
      cvAnalysis: 5850,
      jobMatches: 59,
      price: 2379,
      type: "subscription"
    },
    40: {
      cvAnalysis: 6000,
      jobMatches: 60,
      price: 2440,
      type: "subscription"
    },

    41: {
      cvAnalysis: 6150,
      jobMatches: 62,
      price: 2490,
      type: "subscription"
    },
    42: {
      cvAnalysis: 6300,
      jobMatches: 63,
      price: 2539,
      type: "subscription"
    },
    43: {
      cvAnalysis: 6450,
      jobMatches: 65,
      price: 2589,
      type: "subscription"
    },
    44: {
      cvAnalysis: 6600,
      jobMatches: 66,
      price: 2638,
      type: "subscription"
    },
    45: {
      cvAnalysis: 6750,
      jobMatches: 68,
      price: 2688,
      type: "subscription"
    },

    46: {
      cvAnalysis: 6150,
      jobMatches: 69,
      price: 2737,
      type: "subscription"
    },
    47: {
      cvAnalysis: 6300,
      jobMatches: 71,
      price: 2787,
      type: "subscription"
    },
    48: {
      cvAnalysis: 6450,
      jobMatches: 72,
      price: 2837,
      type: "subscription"
    },
    49: {
      cvAnalysis: 6600,
      jobMatches: 74,
      price: 2886,
      type: "subscription"
    },
    50: {
      cvAnalysis: 6750,
      jobMatches: 75,
      price: 2936,
      type: "subscription"
    },
  }
}