import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { ErrorBoundary } from "react-error-boundary"

import { CV, Spinner } from "../../common"
import { getCV } from "../../../services/cv"
import { setSelectedCVs } from "../../../redux/slices/jobSlice"
import { Resume } from "./components"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

function ResumePage({ showCheckbox, backButton: backBtn }) {
  const [cvData, setCvData] = useState(null)
  const [noData, setNoData] = useState(false)

  const [activeTab, setActiveTab] = useState("profile")

  const { key, jobId } = useParams()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  let backButton = backBtn

  if (jobId) {
    backButton = backButton.replace(":jobId", jobId)
  }

  const selectedCVs = useSelector((state) => state.jobs.selectedCVs)
  const isSelected = selectedCVs.includes(key)

  const handleCheckboxChange = () => {
    const newSetOfSelectedCVs = [...selectedCVs]
    if (isSelected) {
      // Remove
      const index = newSetOfSelectedCVs.indexOf(key)
      newSetOfSelectedCVs.splice(index, 1)
    } else {
      // Add
      newSetOfSelectedCVs.push(key)
    }
    dispatch(setSelectedCVs(newSetOfSelectedCVs))
  }

  useEffect(() => {
    if (key) {
      setCvData(null)
      setNoData(false)
      getCV(key).then((res) => {
        if (res.data.error) {
          toast.error(formatMessage({ id: "toast.unableToFetchCV" }), {
            toastId: "error",
          })
          setNoData(true)
        }
        setCvData(res.data)
      })
    } else {
      setNoData(true)
    }
  }, [key])

  if (!key) {
    return (
      <div className="ResumePage">
        {backButton && (
          <Link className="ResumePage__back-btn" to={backButton}>
            <FormattedMessage id="common.backToCVs" />
          </Link>
        )}
        <p className="ResumePage__no-data">
          <FormattedMessage id="common.pleaseSelectAResume" />
        </p>
      </div>
    )
  }

  if (noData) {
    return (
      <div className="ResumePage">
        {backButton && (
          <Link className="ResumePage__back-btn" to={backButton}>
            <FormattedMessage id="common.backToCVs" />
          </Link>
        )}
        <p className="ResumePage__no-data">
          <FormattedMessage id="common.resumeNotFound" />
        </p>
      </div>
    )
  }

  if (!cvData) {
    return (
      <div className="ResumePage">
        <Link className="ResumePage__back-btn" to={backButton}>
          <FormattedMessage id="common.backToCVs" />
        </Link>
        <div className="ResumePage__loading">
          <Spinner />
        </div>
      </div>
    )
  }

  if (noData || !cvData?.normalised_cv) {
    return (
      <div className="ResumePage">
        {backButton && (
          <Link className="ResumePage__back-btn" to={backButton}>
            <FormattedMessage id="common.backToCVs" />
          </Link>
        )}
        <p className="ResumePage__no-data">
          <FormattedMessage id="common.cvBeingAnalyzed" />
        </p>
      </div>
    )
  }

  const { normalised_cv, summary, skills, pdf_key: cvUrl } = cvData

  const cv = normalised_cv
  const summary_json = summary

  if (!cv || !skills || !summary_json) {
    return (
      <div className="ResumePage">
        {backButton && (
          <Link className="ResumePage__back-btn" to={backButton}>
            <FormattedMessage id="common.backToCVs" />
          </Link>
        )}

        <p className="ResumePage__no-data">
          <FormattedMessage id="common.unableToParseCv" />
        </p>
        <Link className="ResumePage__go-back" to={-1}>
          <FormattedMessage id="message.goBack" />
        </Link>
      </div>
    )
  }

  return (
    <div className="ResumePage">
      <div className="ResumePage__nav">
        {backButton && (
          <Link className="ResumePage__back-btn" to={backButton}>
            <FormattedMessage id="common.backToCVs" />
          </Link>
        )}

        <div className="ResumePage__nav-btns">
          <button
            className={classNames("ResumePage__nav-btn", {
              selected: activeTab === "profile",
            })}
            onClick={() => setActiveTab("profile")}
          >
            <FormattedMessage id="common.profile" />
          </button>
          <button
            className={classNames("ResumePage__nav-btn", {
              selected: activeTab === "cv",
            })}
            onClick={() => setActiveTab("cv")}
          >
            <FormattedMessage id="common.cv" />
          </button>
        </div>
      </div>
      <ErrorBoundary
        fallback={
          <div className="error-msg">
            <p className="h3">
              <FormattedMessage id="common.somethingWentWrong" />
            </p>
            <Link className="btn primary btn--small" to="/">
              <FormattedMessage id="common.goBackToHomepage" />
            </Link>
          </div>
        }
      >
        {activeTab === "profile" && (
          <Resume
            cv={cv}
            summary_json={summary_json}
            skills={skills}
            showCheckbox={showCheckbox}
            handleCheckboxChange={handleCheckboxChange}
            isSelected={isSelected}
          />
        )}
        {activeTab === "cv" && <CV url={cvUrl} />}
      </ErrorBoundary>
    </div>
  )
}

export default ResumePage
