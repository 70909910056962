import { SORT_OPTIONS } from "../../../../constants";

export const filterCVsByWord = (cvs = [], word = "", sort = SORT_OPTIONS.DESC) => {
  const searchFiltered =
    cvs?.filter(({ name, fileName }) => {
      const searchLowered = word.toLowerCase()
      return (
        name?.toLowerCase().includes(searchLowered) ||
        fileName?.toLowerCase().includes(searchLowered)
      )
    }) ?? []

  if (sort === SORT_OPTIONS.ASC) {
    searchFiltered.reverse()
  }

  return searchFiltered
}
