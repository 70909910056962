import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  cvs: null,
}

const cvSlice = createSlice({
  name: "cv",
  initialState,
  reducers: {
    setCvs: (state, action) => {
      state.cvs = action.payload
    },
  },
})

export const { setCvs } = cvSlice.actions

export default cvSlice.reducer
