import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useDropzone } from "react-dropzone"
import classNames from "classnames"

// Redux
import { setSelectedCVs } from "../../../redux/slices/jobSlice"
import { setCredits } from "../../../redux/slices/creditSlice"
import { setCvs } from "../../../redux/slices/cvSlice"

// Components
import {
  Info,
  CVItem,
  NoCv,
  UploadCvActions,
  Pagination,
  Search,
  Sort,
} from "./components"
import { Checkbox, CustomButton, CustomLink, Modal, Spinner } from "../../common"

// Services
import { getCredits } from "../../../services/creditService"
import { deleteCvs, uploadCV } from "../../../services/cv"
import { useProcessedCVsWebSocket } from "../../../services/useProcessedCVsWebSocket"

// Constants and utils
import { GA_EVENTS } from "../../../constants/analytics"
import { SORT_OPTIONS } from "../../../constants"
import { filterCVsByWord } from "./utils"
import { fetchCvs } from "../../../services/utils/fetchCVs"
import { FormattedMessage, useIntl } from "react-intl"

export const statuses = {
  FAILED: "Failed",
  PROCESSING: "Processing",
  DONE: "Done",
}

const DeleteConfirmModal = ({
  cvsLength = 0,
  open = false,
  setOpen = () => {},
  onConfirm = () => {},
  onCancel = () => {},
  loading = true,
}) => (
  <Modal
    open={open}
    setOpen={setOpen}
    className={"delete-dialog"}
  >
    <div className="delete-dialog__container">
      <h2 className="text-2xl font-bold">
        <FormattedMessage id="common.confirmDelete" />
      </h2>
      <p>
        <FormattedMessage
          id="message.areYouSureDeleteCVs"
          values={{ cvsLength }}
        />
      </p>
      {loading && <Spinner />}
      <div className="flex gap-2 delete-dialog__buttons">
        <CustomButton
          className={classNames("btn btn--small primary mr-2", {
            disabled: loading,
          })}
          onClick={onConfirm}
          disabled={loading}
          trackingEvent={GA_EVENTS.SELECT_CV.MODAL_DELETE}
        >
          <FormattedMessage id="common.delete" /> {cvsLength} CV
          {cvsLength > 1 ? "s" : ""}
        </CustomButton>
        <CustomButton
          className="btn btn--small"
          onClick={onCancel}
          trackingEvent={GA_EVENTS.SELECT_CV.MODAL_CANCEL}
        >
          <FormattedMessage id="common.cancel" />
        </CustomButton>
      </div>
    </div>
  </Modal>
)

const SelectCVPage = () => {
  const dispatch = useDispatch()

  const selectedCVs = useSelector((state) => state.jobs.selectedCVs)
  const cvs = useSelector((state) => state.cv.cvs)
  const credit = useSelector((state) => state.credit)

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [perPage] = useState(25)
  const [sort, setSort] = useState(SORT_OPTIONS.DESC)
  const [files, setFiles] = useState([])
  const [status, setStatus] = useState("IDLE")
  const { formatMessage } = useIntl()

  const hasCVsSelected = selectedCVs.length > 0
  const disabled = status === "UPLOADING"

  const filteredCvs = useMemo(() => (
    filterCVsByWord(cvs, search, sort)
  ), [search, sort, cvs])

  const totalPages = useMemo(
    () => Math.ceil((filteredCvs?.length || 0) / perPage),
    [filteredCvs, perPage],
  )

  const hasAllProcessed = useMemo(
    () => cvs?.every((cv) => cv.isProcessed) ?? true,
    [cvs],
  )

  useProcessedCVsWebSocket(!hasAllProcessed && status !== "UPLOADING");

  useEffect(() => {
    dispatch(setSelectedCVs([]))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setPage(1), [filteredCvs])

  const onSelectedCvChange = ({ key, checked }) => {
    const newSetOfSelectedCvs = [...selectedCVs]
    if (checked) {
      newSetOfSelectedCvs.push(key)
    } else {
      const index = newSetOfSelectedCvs.findIndex((val) => val === key)

      newSetOfSelectedCvs.splice(index, 1)
    }

    dispatch(setSelectedCVs(newSetOfSelectedCvs))
  }

  const onDrop = async (acceptedFiles) => {
    const count = acceptedFiles.length

    if (count > credit.cvCredits) {
      toast.error(formatMessage({ id: "toast.notEnoughCredits" }, { count }))
      return
    }

    setStatus("UPLOADING")

    for (let i = 0; i < acceptedFiles.length; i++) {
      const _file = acceptedFiles[i]

      try {
        const formData = new FormData()

        formData.append("file", _file)
        const res = await uploadCV(formData)
        const key = res.data.key

        setFiles((prev) => [
          ...prev,
          {
            fileName: _file.name,
            name: _file.name,
            rawKey: key,
            key: key.split("/").pop().split(".").slice(0, -1).join("."),
            status: statuses.PROCESSING,
          },
        ])
      } catch (err) {
        const message = err?.response?.data?.message

        if (message) {
          toast.error(message)
        }
        setFiles((files) => [
          ...files,
          {
            fileName: _file.name,
            name: _file.name,
            status: statuses.FAILED,
            _file,
          },
        ])
      }
    }
    setStatus("DONE")

    fetchCvs(
      dispatch,
      // callback
      () => setFiles((prev) => prev.filter((pre) => pre.status === statuses.FAILED))
    )

    getCredits().then((credits) => {
      dispatch(setCredits(credits))
    })
  }

  const { getInputProps, open: openFileSelect } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      // Docx
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      // Doc
      "application/msword": [".doc"],
    },
    disabled,
  })

  const handleDeleteConfirm = async () => {
    try {
      const key = showDeleteConfirmModal

      if (!key) {
        return
      }
      setDeleteLoading(true)

      await deleteCvs(selectedCVs)

      toast.success(formatMessage({ id: "toast.cvsDeleted" }))

      dispatch(setSelectedCVs([]))
      dispatch(setCvs(cvs.filter((cv) => !selectedCVs.includes(cv.key))))
    } catch (err) {
      toast.error(
        err?.response?.data?.message ??
          formatMessage({ id: "toast.failedToDeleteCV" }),
      )
    }
    setShowDeleteConfirmModal(false)
    setDeleteLoading(false)
  }

  const handleCVSelectAll = (e) =>
    e.target.checked
      ? dispatch(
          setSelectedCVs(
            processedFilteredCvs.map(({ key }) => key),
          ),
        )
      : dispatch(setSelectedCVs([]))

  if (!cvs) {
    return (
      <div>
        <Spinner />
      </div>
    )
  }

  const processedFilteredCvs = filteredCvs?.filter((cv) => cv.isProcessed)

  const noSelectedCVsUpload = (
    <div className="select-cv__banner">
      <h4 className="select-cv__info h4">
        <span>
          <FormattedMessage id="message.selectCandidates" />
        </span>{" "}
        <FormattedMessage id="message.forJobMatch" />
      </h4>

      <div className="select-cv__buttons">
        <UploadCvActions onUploadClick={openFileSelect} />
      </div>
    </div>
  )

  const selectedCVsUpload = (
    <div className="select-cv__banner">
      <h4 className="select-cv__info h4">
        {selectedCVs.length} <FormattedMessage id="common.selected" />
      </h4>

      <UploadCvActions
        onUploadClick={openFileSelect}
        variant="text"
        triggerText={formatMessage({ id: "common.uploadMoreCVs" })}
      />

      <button
        className="btn warning text btn--small"
        onClick={() => setShowDeleteConfirmModal(true)}
      >
        <FormattedMessage id="common.delete" />
      </button>

      <div className="select-cv__buttons">
        {hasCVsSelected && (
          <CustomLink
            to={"/job-match/job-spec"}
            className="btn primary btn--small"
            trackingEvent={GA_EVENTS.SELECT_CV.ADD_JOB_DESC}
          >
            {nextIcon} <FormattedMessage id="common.nowAddJob" />
          </CustomLink>
        )}
      </div>
    </div>
  )

  const cvListActions = cvs?.length > 0 && (
    <div className="cv-item cv-item--no-bg">
      <Checkbox
        variant="secondary"
        value={selectedCVs.length === processedFilteredCvs?.length}
        onChange={handleCVSelectAll}
        label={formatMessage({ id: "common.all" })}
      />

      <div className="flex items-center">
        <Search search={search} setSearch={setSearch} />
        <Pagination
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={totalPages}
        />
        <Sort sort={sort} setSort={setSort} />
      </div>
    </div>
  )

  const filesList = files?.map((file) => (
    <CVItem
      cv={file}
      setFiles={setFiles}
      key={file.fileName}
      isFile={true}
    />
  ))

  const filteredCvsList = filteredCvs?.slice(perPage * (page - 1), perPage * page).map((cv) => (
    <CVItem
      cv={cv}
      key={cv.key}
      viewLink={`/job-match/select-cv/${cv.key}`}
      selected={selectedCVs.includes(cv.key)}
      onChange={({ key, checked }) =>
        onSelectedCvChange({ key, checked })
      }
    />
  ))

  return (
    <div className="select-cv">
      <input {...getInputProps()} multiple={true} />
      {cvs?.length > 0 && (
        <>
          {selectedCVs?.length === 0
            ? noSelectedCVsUpload
            : selectedCVsUpload
          }
        </>
      )}

      {cvs?.length === 0 && <NoCv onUploadClick={openFileSelect} />}
      <Info />

      <div className="select-cv__cv-wrapper">
        {cvListActions}
        {status === "UPLOADING" && <Spinner />}
        {filesList}
        {filteredCvsList}
      </div>

      <DeleteConfirmModal
        cvsLength={selectedCVs.length}
        open={showDeleteConfirmModal}
        setOpen={setShowDeleteConfirmModal}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setShowDeleteConfirmModal(false)}
        loading={deleteLoading}
      />
    </div>
  )
}

const nextIcon = (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5625" width="25" height="25" rx="12.5" fill="currentColor" />
    <path
      d="M9 13.0625H16M16 13.0625L13 9.0625M16 13.0625L13 17.0625"
      stroke="#5EC58F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SelectCVPage
