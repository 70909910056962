import classNames from "classnames"
import React, { useRef, useState } from "react"
import { AnimatePresence, motion, MotionConfig } from "framer-motion"
import { useIntl } from "react-intl"

const RequirementItem = ({
  section,
  index,
  item,
  editable,
  setEditable,
  setData,
}) => {
  const [text, setText] = useState("")
  const [mouseIn, setMouseIn] = useState(false)
  const [inputWidth, setInputWidth] = useState(0)
  const [minWidth, setMinWidth] = useState(null)

  const ref = useRef()
  const containerRef = useRef()
  const topValueRef = useRef()
  const { formatMessage } = useIntl()

  const onKeyDown = (e) => {
    // if the user presses enter, save the text
    if (e.key === "Enter") {
      setData((data) => {
        const newData = { ...data }
        const thisItem = newData[section][index]
        const originalText = thisItem.originalText || thisItem.text

        newData[section][index].originalText = originalText
        newData[section][index].text = text
        newData[section][index].edited = true

        if (originalText === text) {
          delete newData[section][index].originalText

          if (!item.originalSection) {
            newData[section][index].edited = false
          }
        }

        return newData
      })

      setEditable("")
    }
  }

  const setMustHave = (val) => {
    setData((data) => {
      const newData = { ...data }
      const thisItem = newData[section][index]
      thisItem.isMustHave = val
      return newData
    })
  }

  const deleteItem = () => {
    setData((data) => {
      const newData = { ...data }
      const array = newData[section]
      array.splice(index, 1)
      newData[section] = array
      return newData
    })
  }

  const onLayoutAnimationStart = () => {
    // Grab current position of this element
    const rect = containerRef.current.getBoundingClientRect()
    // Get top value
    const top = rect.top
    topValueRef.current = top
  }

  const onLayoutAnimationComplete = () => {
    // Grab current position of this element
    const rect = containerRef.current.getBoundingClientRect()
    // Get top value
    const top = rect.top
    if (topValueRef.current !== top) {
      topValueRef.current = top
      // Increase minWidth of text content by 120px
      setMinWidth((prev) =>
        prev
          ? prev
          : ref.current.getBoundingClientRect().width + showExperienceInput
          ? 120
          : 70,
      )
    }
  }

  const id = `${section}-${index}`
  const showEditInput = editable === id

  const experienceAllowed = section === "experience"

  const showExperienceInput = experienceAllowed && mouseIn
  const showExperienceText = experienceAllowed && item.experience && !mouseIn

  return (
    <motion.div
      ref={containerRef}
      layout
      className={classNames("requirement")}
      onLayoutAnimationStart={onLayoutAnimationStart}
      onLayoutAnimationComplete={onLayoutAnimationComplete}
    >
      {showEditInput ? (
        <textarea
          style={{ width: inputWidth }}
          className={"requirement__text-input"}
          type="text"
          id={id}
          value={text}
          onBlur={() => setEditable("")}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={onKeyDown}
        />
      ) : (
        <MotionConfig transition={{ ease: "easeInOut", duration: 0.2 }}>
          <motion.div
            ref={ref}
            className="requirement__texts"
            initial="hidden"
            whileHover="visible"
            layout
            onHoverStart={() => setMouseIn(true)}
            onHoverEnd={() => setMouseIn(false)}
          >
            <AnimatePresence mode="sync">
              {item.isMustHave && (
                <motion.div
                  initial={{ width: 20, opacity: 0 }}
                  animate={{ width: "auto", opacity: 1 }}
                  exit={{ width: 0, opacity: 0 }}
                  className="requirement__remove-must-have"
                  onClick={() => setMustHave(false)}
                >
                  {formatMessage({ id: "common.mustHave" }).toLocaleUpperCase()}
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence mode="sync">
              {mouseIn && !item.isMustHave && (
                <motion.button
                  onClick={() => {
                    setMinWidth(null)
                    setMustHave(true)
                  }}
                  className={"requirement__add-must-have"}
                  whileHover={{ width: 70 }}
                  animate={{ width: 40 }}
                  initial={{ width: 20 }}
                  exit={{ width: 0, opacity: 0 }}
                >
                  {starSvg}
                  <span>
                    {formatMessage({ id: "common.must" }).toLocaleUpperCase()}
                  </span>
                </motion.button>
              )}
            </AnimatePresence>
            <AnimatePresence mode="sync">
              {(showExperienceInput || showExperienceText) && (
                <motion.div
                  initial={{ width: 0, opacity: 0 }}
                  animate={{
                    width: showExperienceInput ? 80 : 60,
                    opacity: 1,
                  }}
                  exit={{ width: 0, opacity: 0 }}
                  className={classNames("requirement__experience")}
                >
                  {showExperienceInput ? (
                    <input
                      type="number"
                      className="requirement__experience-input"
                      placeholder={formatMessage({ id: "common.exp" })}
                      value={item.experience}
                      onChange={(e) => {
                        const val = Math.round(Number(e.target.value)) || ""
                        setData((data) => {
                          const newData = { ...data }
                          newData[section][index].experience = val
                          return newData
                        })
                      }}
                    />
                  ) : (
                    <span className="requirement__experience-value">
                      {item.experience}&nbsp;
                    </span>
                  )}

                  <span>
                    {formatMessage({ id: "common.yrs" }).toLocaleUpperCase()}
                  </span>
                </motion.div>
              )}
            </AnimatePresence>

            <motion.p
              style={{ minWidth }}
              layout
              className={classNames("requirement__text")}
            >
              {item.text}
            </motion.p>

            <button
              onClick={() => {
                setText(item.text)
                setEditable(id)
                setInputWidth(ref.current.getBoundingClientRect().width)
              }}
              className="btn btn--icon requirement__edit-btn"
            >
              {editSvg}
            </button>
          </motion.div>

          <button
            onClick={deleteItem}
            className="btn btn--icon requirement__close-btn"
          >
            {closeSvg}
          </button>
        </MotionConfig>
      )}
    </motion.div>
  )
}

const closeSvg = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.343739 5.96425L2.85609 3.4519L0.512031 1.10784L1.32945 0.290427L3.67351 2.63449L6.18586 0.122135L7.09944 1.03572L4.58709 3.54807L6.93115 5.89213L6.11373 6.70954L3.76967 4.36548L1.25732 6.87783L0.343739 5.96425Z"
      fill="#9A8787"
    />
  </svg>
)

const editSvg = (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3913 1.31152C10.5558 1.14708 10.751 1.01664 10.9658 0.927644C11.1807 0.838651 11.411 0.792847 11.6435 0.792847C11.8761 0.792847 12.1063 0.838651 12.3212 0.927644C12.536 1.01664 12.7313 1.14708 12.8957 1.31152C13.0601 1.47595 13.1906 1.67117 13.2796 1.88602C13.3686 2.10087 13.4144 2.33114 13.4144 2.56369C13.4144 2.79625 13.3686 3.02652 13.2796 3.24137C13.1906 3.45622 13.0601 3.65143 12.8957 3.81587L4.44349 12.2681L1 13.2072L1.93913 9.76372L10.3913 1.31152Z"
      stroke="#18A8D5"
      strokeWidth="1.25218"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const starSvg = (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 2L11.1839 7.18237H16.6329L12.2245 10.3853L13.9084 15.5676L9.5 12.3647L5.09161 15.5676L6.77547 10.3853L2.36708 7.18237H7.81614L9.5 2Z"
      fill="white"
      stroke="#D1CFCF"
    />
  </svg>
)

export default RequirementItem
