import React from "react"

const Skill = (props) => {
  return (
    <div className="skill flex-baseline">
      <div className="score middle" style={{ opacity: props.score / 10 }}>
        {Math.round(props.score)}
      </div>
      <div className="skill-wrap flex-col">
        <h4>
          {props.skill} <span className="tag">{props.tag}</span>
        </h4>

        <p>{props.context}</p>
      </div>
    </div>
  )
}

export default Skill
