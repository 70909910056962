// components/Page2.js
import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import {
  setAlreadyMatchedOnce,
  setAreRequirementsSend,
  setJobs,
  setRequirements,
  setSelectedCVs,
  setTitle,
  setWeights,
} from "../../../redux/slices/jobSlice"
import {
  getJobRequirements,
  postMatchRequirements,
} from "../../../services/jobs"
import { CustomLink, MaxCandidateSelect } from "../../common"
import { Processing, Requirements, Weights } from "./components"
import { GA_EVENTS } from "../../../constants/analytics"
import { FormattedMessage } from "react-intl"

const RequirementsPage = () => {
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(false)
  const credit = useSelector((state) => state.credit)

  const [submitLoading, setSubmitLoading] = useState(false)

  const jobs = useSelector((state) => state.jobs)
  const [data, setData] = useState({})
  const [weightsData, setWeightsData] = useState({})

  const { alreadyMatchedOnce } = jobs

  const params = useParams()

  const title = useSelector((state) => state.jobs.title)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const jobID = params.jobId

  useEffect(() => {
    if (!jobID) {
      navigate("/job-match/select-cv")
    }
  }, [jobID, navigate])

  useEffect(() => {
    if (jobID) {
      setLoading(true)
      // try every 2 seconds to get the requirements
      const getRequirements = async () => {
        const { data } = await getJobRequirements({
          ad_id: jobID,
        })
        if (data?.requirements) {
          const keys = Object.keys(data.weights || {})
          const weights = {
            skills_weight: 0.3333333,
            education_weight: 0.3333333,
            experience_weight: 0.3333334,
          }
          keys.forEach((key) => (weights[key] = Number(data.weights[key])))
          return {
            requirements: data.requirements,
            title: data.title,
            alreadyMatchedOnce: Boolean(data.top_k),
            job_meta: data.job_meta,
            weights,
            selected_candidates: data.selected_candidates,
          }
        } else {
          return null
        }
      }

      const max_tries = 100
      let tries = 0

      const interval = setInterval(async () => {
        tries += 1
        let requirementsData = await getRequirements()

        if (requirementsData) {
          const requirements =
            typeof requirementsData.requirements === "string"
              ? JSON.parse(requirementsData.requirements)
              : requirementsData.requirements

          const filteredMustHaves = Object.keys(
            requirementsData.job_meta.requirements || {},
          ).reduce((acc, key) => {
            acc[key] = []

            const data = requirementsData.job_meta.requirements[key]

            Object.keys(data).forEach((val) => {
              const [text, type] = val.split("__")
              if (type === "gte") {
                acc[key].push(text)
              }
            })

            return acc
          }, {})

          const experiences = Object.keys(
            requirementsData?.job_meta?.requirements?.experience || {},
          ).reduce((acc, key) => {
            const [text, type] = key.split("__")
            if (type === "exp") {
              acc[text] =
                requirementsData?.job_meta?.requirements?.experience[key]
            }
            return acc
          }, {})

          const filteredRequirements = Object.keys(requirements ?? {}).reduce(
            (obj, key) => {
              return (obj[key] = {
                ...obj,
                [key]: requirements[key]?.map?.((text) => {
                  return {
                    isEditing: false,
                    text: text,
                    deleted: false,
                    isMustHave: filteredMustHaves[key]?.includes(text) ?? false,
                    experience:
                      key === "experience" ? experiences[text] || "" : "",
                  }
                }),
              })
            },
            {},
          )

          dispatch(setSelectedCVs(requirementsData.selected_candidates))
          dispatch(setWeights(requirementsData.weights))

          dispatch(setAlreadyMatchedOnce(requirementsData.alreadyMatchedOnce))
          dispatch(
            setRequirements({
              jobId: jobID,
              requirements: filteredRequirements,
            }),
          )
          dispatch(setTitle(requirementsData.title))
          setLoading(false)
          setProcessing(false)

          setWeightsData({ ...requirementsData.weights })
          setData(JSON.parse(JSON.stringify(filteredRequirements)))

          clearInterval(interval)
        } else if (tries > max_tries) {
          // Navigate to job match select cv page
          navigate("/job-match/select-cv")
          clearInterval(interval)
          setLoading(false)
        } else {
          setProcessing(true)
        }
      }, 2000)

      return () => clearInterval(interval)
    }
  }, [jobID, dispatch, navigate])

  const clearSelectedCVs = () => dispatch(setSelectedCVs([]))

  const handleClick = async (maxCandidates, threshold) => {
    if (!maxCandidates && !threshold) return

    setSubmitLoading(true)

    dispatch(setRequirements({ requirements: data, jobId: jobID }))
    // replace with your API endpoint and pass the relevant data
    const dataToSend = Object.keys(data).reduce((acc, key) => {
      return (acc[key] = {
        ...acc,
        [key]: data[key].map((item) => item.text),
      })
    }, {})

    let data_api = {
      ad_id: jobID,
      requirements: dataToSend,
      weights: weightsData,
    }

    if (threshold) {
      data_api.job_meta = {
        threshold,
      }

      data_api.max_candidates = jobs.selectedCVs.length
    } else {
      data_api.max_candidates = maxCandidates + 15
      data_api.job_meta = {
        actual_topK: maxCandidates,
      }
    }

    data_api.job_meta.requirements = Object.keys(data).reduce((acc, key) => {
      const section = key
      const sectionData = data[key]

      acc[section] = {}

      sectionData.forEach((item) => {
        if (item.isMustHave) {
          acc[section][`${item.text}__gte`] = "5"
        }
        if (item.experience) {
          acc[section][`${item.text}__exp`] = item.experience
        }
      })

      return acc
    }, {})

    // send a post request to the API; data_api is the data to be sent
    await postMatchRequirements(data_api)

    dispatch(setAreRequirementsSend(true))

    setSubmitLoading(false)
    navigate(`/job-match/${jobID}/matches`)
    // Clear selected CVs
    clearSelectedCVs()

    // Clear jobs
    dispatch(setJobs(null))
  }

  if (processing) {
    return <Processing />
  }

  if (loading) {
    return (
      <div className="requirements-page">
        <div className="step">
          <FontAwesomeIcon icon={faCog} spin />{" "}
          <FormattedMessage id="common.loading" />
        </div>
      </div>
    )
  }

  if (submitLoading) {
    return (
      <div className="requirements-page">
        <div className="step">
          <FontAwesomeIcon icon={faCog} spin />{" "}
          <FormattedMessage id="common.processing" />
          (<FormattedMessage id="common.processingTime" />)
        </div>
      </div>
    )
  }

  return (
    <div className="requirements-page">
      {!submitLoading ? (
        <div className="requirements-page__content">
          <div className="requirements-page__header">
            <h3 className="requirements-page__title h3">
              <FormattedMessage id="message.checkThroughMessage" />{" "}
              <span>
                <FormattedMessage id="message.skillsForRole" />
              </span>
            </h3>
            <p className="p">
              <FormattedMessage id="message.calculateScoresMessage" />
            </p>
          </div>

          <div className="requirements-page__topbar">
            <p className="requirements-page__">{title}</p>
          </div>

          {alreadyMatchedOnce && (
            <p className="requirements-page__info">
              <FormattedMessage id="message.updateRequirementsMessage" />
              <CustomLink
                to={`/job-match/${jobID}/matches`}
                trackingEvent={GA_EVENTS.REQUIREMENTS.CANCEL_AND_BACK}
              >
                <FormattedMessage id="common.cancelAndGoBack" />
              </CustomLink>
            </p>
          )}

          <Requirements data={data} setData={setData} />

          {credit.jobAdvertCredits === 0 && (
            <p className="requirements-page__info">
              <FormattedMessage id="message.youHaveUsedYourCredits" />
              <CustomLink
                to={"/buy-more"}
                trackingEvent={GA_EVENTS.REQUIREMENTS.ADD_MORE_CREDITS}
              >
                <FormattedMessage id="common.addMoreHere" />
              </CustomLink>
            </p>
          )}
          <Weights
            weightsData={weightsData}
            setWeightsData={setWeightsData}
            action={
              <MaxCandidateSelect
                disabled={Boolean(!credit.jobAdvertCredits)}
                onClick={(val, threshold) => handleClick(val, threshold)}
              />
            }
          />
        </div>
      ) : (
        <div className="step">
          <FontAwesomeIcon icon={faCog} spin />{" "}
          <FormattedMessage id="common.processing" />
          (<FormattedMessage id="common.processingTime" />)
        </div>
      )}
    </div>
  )
}

export default RequirementsPage
