import { faCog } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { FormattedMessage } from "react-intl"

const Processing = () => {
  return (
    <div className="add-job-spec">
      <h3 className="add-job-spec__title h3">
        <FormattedMessage id="common.processing" />
      </h3>

      <div className="add-job-spec__topbar flex-col">
        <h4 className="first">
          <FormattedMessage id="common.findingMustHaves" />{" "}
          <FontAwesomeIcon icon={faCog} spin />
        </h4>
        <h4 className="second">
          <FormattedMessage id="common.findingSoftSkills" />{" "}
          <FontAwesomeIcon icon={faCog} spin />
        </h4>
        <h4 className="third">
          <FormattedMessage id="common.findingHardSkills" />{" "}
          <FontAwesomeIcon icon={faCog} spin />
        </h4>
        <h4 className="fourth">
          <FormattedMessage id="common.findingAdvantages" />{" "}
          <FontAwesomeIcon icon={faCog} spin />
        </h4>
        <h4 className="fifth">
          <FormattedMessage id="common.compilingRequirements" />{" "}
          <FontAwesomeIcon icon={faCog} spin />
        </h4>
        <p>
          <FormattedMessage id="common.thisCanTakeTime" />
        </p>
      </div>
    </div>
  )
}

export default Processing
