import React from "react"
import { Checkbox, SkillList } from "../../../common"
import { FormattedMessage, useIntl } from "react-intl"

const Resume = ({
  cv,
  summary_json,
  skills,
  showCheckbox,
  handleCheckboxChange,
  isSelected,
}) => {
  const { formatMessage } = useIntl()
  return (
    <div className="ResumePage__content">
      <div className="flex-col">
        <div className="section-card topbar flex-col">
          <h1>{cv.name}</h1>

          {showCheckbox && (
            <button
              className="ResumePage__checkbox"
              onClick={handleCheckboxChange}
            >
              {isSelected
                ? formatMessage({ id: "common.selectedForJobMatch" })
                : formatMessage({ id: "common.selectForJobMatch" })}
              <Checkbox
                onClick={(e) => e.stopPropagation()}
                variant="tertiary"
                onChange={handleCheckboxChange}
                value={isSelected}
              />
            </button>
          )}

          {cv.contactInfo?.phoneNumbers
            ?.filter((num) => num)
            ?.map((number, index) => (
              <p key={index}>
                <strong>
                  <FormattedMessage id="common.phone" />{" "}
                </strong>{" "}
                {number}
              </p>
            ))}

          {cv.contactInfo?.emails
            ?.filter((num) => num)
            ?.map((email, index) => (
              <p key={index}>
                <strong>
                  <FormattedMessage id="common.email" />{" "}
                </strong>{" "}
                {email}
              </p>
            ))}

          {cv.contactInfo?.websites?.[0] &&
            cv.contactInfo.websites?.map((website, index) => (
              <p key={index}>
                <strong>
                  <FormattedMessage id="common.website" />{" "}
                </strong>
                <span className="long-text">{website}</span>
              </p>
            ))}

          {cv.dateOfBirth && (
            <p>
              <strong>
                <FormattedMessage id="common.dateOfBirth" />{" "}
              </strong>{" "}
              {cv.dateOfBirth}
            </p>
          )}

          {cv.contactInfo?.addresses?.map((address, index) => (
            <p>
              <strong>
                <FormattedMessage id="common.address" />{" "}
              </strong>
              {address.street && `${address.street}, `}
              {address.city && `${address.city}, `}
              {address.zip && `${address.zip}, `}
              {address.country && `${address.country}`}
            </p>
          ))}

          <h4>{cv.summary}</h4>
        </div>

        <div className="section-card overview flex-col">
          <h3>
            <FormattedMessage id="common.highlights" />
          </h3>

          {cv.skills?.[0]?.name && (
            <div className="skill-tags flex-wrap">
              {cv.skills?.map((skill, index) => (
                <div className="tag" key={index}>
                  {skill.name}
                </div>
              ))}
            </div>
          )}
          <p>
            <strong>
              <FormattedMessage id="common.mostSeniorLevel" />:
            </strong>{" "}
            {summary_json["most_senior_level"]}
          </p>
          <p>
            <strong>
              <FormattedMessage id="common.keyIndustries" />:
            </strong>{" "}
            {summary_json["key_industries"]?.join(", ")}
          </p>

          <p>
            <strong>
              <FormattedMessage id="common.suitableRoles" />:
            </strong>{" "}
            {summary_json["suitable_roles"]?.join(", ")}
          </p>

          <p>
            <strong>
              <FormattedMessage id="common.mainStrengths" />:
            </strong>{" "}
            {summary_json["main_strengths"]?.join(", ")}
          </p>
          <p>
            <strong>
              <FormattedMessage id="common.technicalSkills" />:
            </strong>{" "}
            {summary_json["technical_keywords"]?.join(", ")}
          </p>
        </div>
      </div>

      <div>
        <div className="section-card skills">
          <SkillList skills={skills || []} />
        </div>

        <div className="work-experience flex-col">
          <div className="skill">
            <h3>
              <FormattedMessage id="common.experience" />
            </h3>
          </div>

          <div className="roles">
            {cv.workExperience?.map((experience, index) => (
              <>
                <div className="role flex-col skill">
                  <h4 className="strong">{experience.position}</h4>
                  <h4>{experience.company}</h4>
                  <div className="dates">
                    {`${experience.startDate} ${formatMessage({
                      id: "common.to",
                    })} ${experience.endDate}`}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        <div className="education flex-col">
          <div className="skill">
            <h3>
              <FormattedMessage id="common.education" />
            </h3>
          </div>

          <div className="schools">
            {cv.education?.map((education) => (
              <>
                <div className="role flex-col skill">
                  <h4 className="strong">{education.degree}</h4>
                  <h4>{education.school}</h4>
                  <div className="dates">
                    {`${
                      education.totalYears
                        ? `${education.totalYears} ${formatMessage({
                            id: "common.from",
                          })}`
                        : ""
                    } ${
                      education.startDate && education.endDate
                        ? `${education.startDate} ${formatMessage({
                            id: "common.to",
                          })} ${education.endDate}`
                        : ""
                    }`}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        {cv.certifications?.[0]?.name && (
          <div className="education flex-col">
            <div className="skill">
              <h3>
                <FormattedMessage id="common.certifications" />
              </h3>
            </div>
            <div className="schools">
              {cv.certifications?.map((certification, index) => (
                <p className="role flex-col skill" key={`Cert - ${index}`}>
                  {certification.name}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Resume
