import React from "react"
import { SkillList } from "../../../common"
import { FormattedMessage, useIntl } from "react-intl"

const Profile = ({ cv, summary_json, skills }) => {
  const tags = summary_json?.technical_keywords || []
  const { formatMessage } = useIntl()

  const highlightItems = [
    {
      key: "Most senior level",
      value: summary_json.most_senior_level,
    },
    {
      key: "Key industries",
      value: summary_json.key_industries?.join(", "),
    },
    {
      key: "Suitable roles",
      value: summary_json.suitable_roles?.join(", "),
    },
    {
      key: "Main strengths",
      value: summary_json.main_strengths?.join(", "),
    },
  ]

  return (
    <div className="profile">
      <div class="profile__highlights-wrapper">
        <div class="profile__highlights-item">
          <p class="profile__highlights-title">
            <FormattedMessage id="common.highlights" />
          </p>
        </div>

        <div class="profile__highlights-item">
          <div class="profile__highlights-tags">
            {tags.map((tag, i) => (
              <p key={`Tag - ${i}`} class="profile__highlights-tag">
                {tag}
              </p>
            ))}
          </div>
        </div>

        {highlightItems.map(({ key, value }, i) => (
          <div class="profile__highlights-item" key={`Highlight - ${i}`}>
            <p class="profile__highlights-info">
              <span> {key}: </span>
              {value}
            </p>
          </div>
        ))}
      </div>

      <div>
        <div className="skills">
          <SkillList skills={skills || []} />
        </div>

        <div className="work-experience flex-col">
          <div className="skill">
            <h3>
              <FormattedMessage id="common.experience" />
            </h3>
          </div>

          <div className="roles">
            {cv.workExperience?.map((experience, index) => (
              <div className="role flex-col skill">
                <h4 className="strong">{experience.position}</h4>
                <h4>{experience.company}</h4>
                <div className="dates">
                  {`${experience.startDate} ${formatMessage({
                    id: "common.to",
                  })} ${experience.endDate}`}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="education flex-col">
          <div className="skill">
            <h3>
              <FormattedMessage id="common.education" />
            </h3>
          </div>

          <div className="schools">
            {cv.education?.map((education) => (
              <>
                <div className="role flex-col skill">
                  <h4 className="strong">{education.degree}</h4>
                  <h4>{education.school}</h4>
                  <div className="dates">
                    {`${
                      education.totalYears
                        ? `${education.totalYears} ${formatMessage({
                            id: "common.from",
                          })}`
                        : ""
                    } ${
                      education.startDate && education.endDate
                        ? `${education.startDate}  ${formatMessage({
                            id: "common.to",
                          })} ${education.endDate}`
                        : ""
                    }`}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        {cv.certifications?.[0]?.name && (
          <div className="education flex-col">
            <div className="skill">
              <h3>
                <FormattedMessage id="common.certifications" />
              </h3>
            </div>
            <div className="schools">
              {cv.certifications?.map((certification, index) => (
                <p className="role flex-col skill" key={`Cert - ${index}`}>
                  {certification.name}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Profile
