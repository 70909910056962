import React from "react"
import Tooltip from "../Tooltip"
import { copyToClipboard } from "../../../util/util"
import { FormattedMessage, useIntl } from "react-intl"

const titleMap = {
  qa_justification: "Q/R",
  education: "Education",
  experience: "Experience",
  skills: "Skills",
}

function CandidateScore({ data, jobQa }) {
  const { formatMessage } = useIntl()
  if (!data) {
    return null
  }
  const cv = data.normalised_cv

  let categoryAveragesObj = data.score_meta || {}
  categoryAveragesObj = { ...categoryAveragesObj }

  const scores = { ...data.scores }
  if (data.qa_justification.length > 0) {
    scores.qa_justification = {}

    data.qa_justification.forEach((qaJust) => {
      const questionId = Object.keys(qaJust)[0]
      const qa = qaJust[questionId]
      if (jobQa) {
        const question = jobQa[questionId]

        scores.qa_justification[question] = {
          explanation: qa.justification,
          score: qa.score / 10,
        }
      }
    })

    categoryAveragesObj.qa_justification =
      data.qa_justification.reduce((acc, qaJust) => {
        const questionId = Object.keys(qaJust)[0]
        const qa = qaJust[questionId]
        return acc + Number(qa.score) / 10
      }, 0) / data.qa_justification.length
  }

  return (
    <div className="candidate_container">
      <div>
        <div className="info-block flex">
          <div className="contact-details flex-col">
            {cv.contactInfo?.phoneNumbers
              ?.filter((val) => val)
              ?.map((number, index) => (
                <Tooltip tooltip={"Click to copy"} key={`Phone - ${index}`}>
                  <p onClick={() => copyToClipboard(number)}>
                    <span>Phone </span> {number}
                  </p>
                </Tooltip>
              ))}

            {cv.contactInfo?.emails
              ?.filter((val) => val)
              ?.map((email, index) => (
                <Tooltip tooltip={"Click to copy"} key={`Email - ${index}`}>
                  <p onClick={() => copyToClipboard(email)}>
                    <span>Email </span> {email}
                  </p>
                </Tooltip>
              ))}

            {cv.contactInfo?.websites[0] &&
              cv.contactInfo.websites?.map((website, index) => (
                <Tooltip tooltip={"Click to copy"} key={`Website - ${index}`}>
                  <p onClick={() => copyToClipboard(website)}>
                    <span>Website </span> {website}
                  </p>
                </Tooltip>
              ))}

            {cv.dateOfBirth && (
              <p>
                <span>Date of Birth </span> {cv.dateOfBirth}
              </p>
            )}

            {cv.contactInfo?.addresses[0].city &&
              cv.contactInfo.addresses?.map((address, index) => (
                <p key={`Addr - ${index}`}>
                  <span>Address </span>
                  {address.street && `${address.street}, `}
                  {address.city && `${address.city}, `}
                  {address.zip && `${address.zip}, `}
                  {address.country && `${address.country}`}
                </p>
              ))}
          </div>
          <div className="summary ">
            <p key={137}>{data.normalised_cv.summary}</p>
          </div>
        </div>
        {Object.entries(scores).map(([key, values], i) => (
          <div className="box flex" key={`Score - ${i}`}>
            <div className="sectionName flex-col">
              <div
                className="pieOuter"
                style={{
                  background: `conic-gradient(var(--fribl-blue) ${
                    /* score to angle */
                    categoryAveragesObj[key] * 36
                  }deg, var(--white) 0deg)`,
                }}
              >
                <p>{Math.round(categoryAveragesObj[key])}</p>
              </div>

              <p className="skillType">
                <b>{titleMap[key]}</b>{" "}
              </p>
            </div>
            <div key={key} className="section-card">
              {Object.entries(values).map(([name, details], j) => (
                <div key={`Score ${i}${j}`}>
                  <div className="row">
                    <strong
                      className="score"
                      style={{ opacity: details.score / 10 }}
                    >
                      {details.score}
                    </strong>
                    <div className="nameEvidenceContainer">
                      {details.score > 0 ? (
                        <strong className="name">{name}</strong>
                      ) : (
                        <p className="name weak">
                          {name} <em>No&nbsp;evidence</em>
                        </p>
                      )}
                      <div className="evidence">
                        <em>Evidence: </em>
                        {details.explanation}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CandidateScore
