import React, { useState } from "react"
// import Select, { weightsSelectOptions } from "./Select"
import { useSelector } from "react-redux"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import { FormattedMessage } from "react-intl"

const weightOptions = [
  {
    key: "skills_weight",
    label: "common.skills",
  },
  {
    key: "education_weight",
    label: "common.education",
  },
  {
    key: "experience_weight",
    label: "common.experience",
  },
]

const otherWeights = {
  skills_weight: ["education_weight", "experience_weight"],
  education_weight: ["skills_weight", "experience_weight"],
  experience_weight: ["skills_weight", "education_weight"],
}

const Weights = ({ action, weightsData, setWeightsData }) => {
  const { weights: storeWeights } = useSelector((state) => state.jobs)
  const [weightsChanged, setWeightsChanged] = useState(false)

  const change = (key, newValue) => {
    setWeightsChanged(true)

    setWeightsData((weightsData) => {
      const value = Number(newValue.toFixed(3))
      let val1 = 0,
        val2 = 0

      const [key1, key2] = otherWeights[key]

      if (value !== 1) {
        const diff = Number(((weightsData[key] - value) / 2).toFixed(7))

        val1 = Math.max(Number((diff + weightsData[key1]).toFixed(7)), 0)

        val2 = Number((1 - value - val1).toFixed(7))

        if (val2 < 0) {
          val1 = 1 - value
          val2 = 0
        }
      }

      const newWeights = {
        [key]: value,
        [key1]: val1,
        [key2]: val2,
      }

      return newWeights
    })
  }

  const handleReset = () => {
    setWeightsData({ ...storeWeights })
    setWeightsChanged(false)
  }

  return (
    <div className="weights">
      <p className="weights__title">
        <FormattedMessage id="message.moveSlidersForMatching" />{" "}
      </p>

      <div className="weights__sliders">
        <div className="weight">
          <div />

          <div className="weight__labels">
            <span className="weight__label p">
              <FormattedMessage id="common.less" />
            </span>
            <span className="weight__label p">
              <FormattedMessage id="common.more" />
            </span>
          </div>
        </div>
        {weightOptions.map((weight, i) => (
          <div key={`Weight - ${i}`} className="weight">
            <p className="weight__label p">
              <FormattedMessage id={weight.label} />
            </p>

            <div className="weight__slider">
              <Slider
                onChange={(nextValues) => change(weight.key, nextValues)}
                onChangeComplete={(v) => change(weight.key, v)}
                min={0}
                max={1}
                value={weightsData[weight.key]}
                step={0.001}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="weights__buttons">
        {action}
        {weightsChanged && (
          <button onClick={handleReset} className="btn senary btn--small">
            <FormattedMessage id="common.reset" />
          </button>
        )}
      </div>
    </div>
  )
}

export default Weights
