import React, { createContext, useState, useEffect } from "react"
import { SUPPORTED_LANGUAGES } from "../constants"
import { getCookie, setCookie } from "../util/util"
const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {
  const getInitialLanguage = () => {
    const savedLanguage = getCookie("NEXT_LOCALE")
    let returnLanguage = savedLanguage || navigator.language

    if (SUPPORTED_LANGUAGES.map(({ code }) => code).includes(returnLanguage))
      return returnLanguage
    return "en"
  }

  const [language, setLanguage] = useState(getInitialLanguage)

  useEffect(() => {
    setCookie("NEXT_LOCALE", language)
  }, [language])

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage)
  }

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
